import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import SEO from '../components/Layout/SEO';

const AboutPageStyled = styled.div`
  .about-header {
    width: 100vw;
    padding: 60px 0;
    text-align: center;
    background: green;
    font-weight: 700;
    color: var(--heading-colour);

    background-color: #ffffff;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23ecf0f1' fill-opacity='0.8' fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  }

  .about {
    padding: 60px 0;
  }

  p {
    margin: 30px 0;
  }
`;

export default function AboutPage({ location }) {
  return (
    <AboutPageStyled>
      <SEO title="About Us" location={location.href} />

      <section className="about-header">
        <h2>About Us</h2>
      </section>

      <section className="about">
        <div className="containerSmaller">
          <h3>The Little Lighthouse Co</h3>
          <p>
            The Little Lighthouse focuses on being environmentally friendly,
            whilst making beautiful items for within the home. Because why can’t
            those things be combined?
          </p>

          <p>
            We use so much plastic in our day-to-day lives within our homes. Our
            goal Is to reduce this, in a stylish way. We’re not asking you to be
            perfect, And to never use plastic again. But simply reduce what we
            can.
          </p>

          <p>
            What started out as making a refillable hand wash bottle for our own
            home, flourished into the idea for starting TLL. All of our bottles
            can be washed out and refilled, and best of all. All of our designs
            can be personalised to you! Do you want something a little different
            to our product photo, simply ask, We’re happy to do one off designs!
          </p>
        </div>
      </section>
    </AboutPageStyled>
  );
}

AboutPage.propTypes = {
  location: PropTypes.object.isRequired,
};
